<template>
    <div class="maed">
        <div class="gong_name">
            <div class="borderDiv"></div>
            <div class="titDiv">
                <p class="name_p">产品特色</p>
                <p class="eng_p">PRODUCT FEATURES</p>
            </div>
        </div>
        <div class="swiperDiv">
            <div class="swip">
                <img src="../../assets/imgs/swp1.png" alt="">
                <p>服务范围广：包括技术服务、数据服务、运营服务、营销服务等。</p>
            </div>
            <div class="swip">
                <img src="../../assets/imgs/swp2.png" alt="">
                <p>用户体验好：深度挖掘用户心理及使用习惯，注重交互模式和细节，打造绝佳用户体验。</p>
            </div>
            <div class="swip">
                <img src="../../assets/imgs/swp3.png" alt="">
                <p>团队配置强：创始人及团队深耕互联网信息科技服务行业10年以上。</p>
            </div>
            <div class="swip">
                <img src="../../assets/imgs/swp4.png" alt="">
                <p>技术更创新：自主研发平台，可根据企业需求定制一体化在线服务。</p>
            </div>
            <div class="swip">
                <img src="../../assets/imgs/swp5.png" alt="">
                <p>数据更安全：通过互联网、大数据等创新性科技手段，提高企业运营效率，保障数据安全。</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.maed {
    width: 85%;
    height: 58%;
    // background: red;
    position: relative;
    left: 0;
    top: 90px;
    padding: 74px 0 10px 200px;
    .gong_name {
        width: 266px;
        height: 108px;
        position: relative;
        .borderDiv {
            width: 208px;
            height: 108px;
            border-radius: 4px;
            border: 10px solid #f0f0f0;
            position: absolute;
            left: 60px;
            top: -30px;
            z-index: -1;
        }
        .titDiv {
            text-align: left;
            .name_p {
                color: #2e2133;
                font-size: 36px;
                font-weight: 600;
            }
            .eng_p {
                color: #5c6366;
                font-size: 14px;
                font-weight: 600;
            }
        }
    }
    .swiperDiv {
        margin-top: 36px;
        // background: blue;
        height: 300px;
        width: 100%;
        display: flex;
        .swip {
            width: 270px;
            height: 300px;
            margin: 0 4px;
            // background: red;
            text-align: left;
            position: relative;
            img {
                width: 100%;
                height: 100%;
                z-index: -1;
            }
            p {
                font-size: 16px;
                color: #ffffff;
                font-weight: normal;
                line-height: 24px;
                z-index: 999999;
                position: absolute;
                left: 0;
                top: 220px;
                margin: 0 10px;
            }
        }
    }
}
</style>